@import './vars.scss';
.articleContent {
  @include open-sans();
  color: $textGray;
  text-align: justify;
  table {
    font-family: verdana, arial, sans-serif;
    font-size: 11px;
    border-width: 1px;
    border-color: $borderLight;
    border-collapse: collapse;
    th {
      color: $textBlack;
      border-width: 1px;
      padding: 8px;
      border-style: solid;
      border-color: $border;
      background-color: #dedede;
    }
    td {
      border-width: 1px;
      padding: 8px;
      border-style: solid;
      border-color: $border;
    }
  }
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 30px;
    &::before {
      display: none;
    }
  }
  hr {
    border: 0;
    height: 1px;
    background-color: $border;
  }
  img {
    max-width: 100%;
    margin-top: 24px;
    // height: unset;
    margin-left: 24px;
  }
  div {
    width: fit-content;
    display: grid;
    // // justify-content: space-between;
    // grid-gap:24px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  a {
    text-decoration: underline;
    color: #1155cc;
  }
  // span,strong {
  //   strong {
  //     @include open-sans-semibold();
  //   }
  // }
  b {
    @include open-sans-semibold();
  }
  ol,
  ul {
    padding-left: 30px;
  }
  ol {
    li {
      list-style: decimal;
    }
  }
  ul {
    li {
      list-style: disc;
    }
  }
}
