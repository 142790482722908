$cdnURL: 'https://negosyo.test.shopee.ph/publish_static/c3ec22f6-90d5-4979-829d-13ac33c86f98/6791';
@import '../../styles/vars';
.fixedHeader {
  padding-top: 64px;
}
.layoutWrapper {
  min-height: 100vh;
}

.footer {
  // margin-top: 120px;
  border-top: 1px solid $borderLight;
}
