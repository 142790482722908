$cdnURL: 'https://negosyo.test.shopee.ph/publish_static/c3ec22f6-90d5-4979-829d-13ac33c86f98/6791';
@import '../../styles/vars.scss';
@import '../../styles/mixins/index.scss';
.footerWrapper {
  height: 425px;
  font-size: 14px;
  .footer {
    &Top {
      padding-top: 48px;
      height: 343px;
      border-bottom: 1px solid $borderLight;
    }
    &Left {
      //   width: 110px;
      float: left;
      p {
        margin-top: 48px;
        margin-bottom: 8px;
        @include open-sans();
        font-size: 14px;
        line-height: 24px;
        color: rgba(32, 35, 37, 0.6);
      }
      .borderBox {
        width: 175px;
        height: 36px;
        border: 1px solid $mainColor;
        box-sizing: border-box;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 16.38px;
          height: 22.45px;
        }
        span {
          font-size: 20px;
          line-height: 20px;
          @include shopee-2021();
          color: $mainColor;
        }
      }
    }
    &Right {
      //   width: calc(100% - 110px);
      float: right;
      display: flex;
      justify-content: flex-end;
      .IconGroup {
        // margin-top: 38px;
        display: flex;

        &Left {
          img {
            display: block;
            width: 118px;
            height: 36px;
          }
          img:first-child {
            margin-bottom: 20px;
          }
        }
        &Right {
          margin-left: 24px;
          width: 76px;
          height: 96px;
          border-radius: 8px;
          background-color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid rgba(0, 0, 0, 0.09);
          img {
            margin-top: 5px;
            width: 66px;
            height: 66px;
          }
          p {
            text-align: center;
            @include open-sans-semibold();
            width: 90px;
            height: 30px;
            font-size: 12px;
            line-height: 15px;
            transform: scale(0.666);
            margin-top: -4px;
            letter-spacing: -0.02em;
          }
        }
      }
    }

    &Bottom {
      padding-top: 24px;
      height: calc(100% - 343px);
      text-align: center;
      text-transform: uppercase;
      color: $textBlack;
      opacity: 0.6;
    }
  }
}
