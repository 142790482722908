@font-face {
  font-family: 'Open Sans';
  src: url('#{$cdnURL}/fonts/OpenSans-Regular.ttf');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans SemiBold';
  src: url('#{$cdnURL}/fonts/OpenSans-SemiBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Shopee 2021 Bold';
  src: url('#{$cdnURL}/fonts/Shopee2021-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Shopee 2021';
  src: url('#{$cdnURL}/fonts/Shopee2021-Regular.ttf');
  font-display: swap;
}
