$cdnURL: 'https://negosyo.test.shopee.ph/publish_static/c3ec22f6-90d5-4979-829d-13ac33c86f98/6791';
@import '../../styles/vars.scss';
@import '../../styles/mixins/index.scss';
.headerWrapper {
  position: relative;
  z-index: 100;
  font-size: 14px;
  overflow-y: visible !important;
  border-bottom: 1px solid $border;
  .fixedBox {
    position: fixed;
    width: 980px;
    @media only screen and (min-width: 1420px) {
      width: 1260px;
    }
  }
  .header {
    &Container {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &Left {
      cursor: pointer;
    }
    &Right {
      display: flex;
      // width: 500px;
      height: 36px;
      align-items: center;
      justify-content: flex-start;
    }
    &Middle {
      width: fit-content;
      display: block;
      flex: 1;
      margin-right: 60px;
      ul {
        li {
          text-align: center;
          display: inline;
          padding-left: 60px;
          &:hover {
            color: $primary;
          }
        }
        li.active {
          color: $primary;
        }
      }
    }
  }
}
