$cdnURL: 'https://negosyo.test.shopee.ph/publish_static/c3ec22f6-90d5-4979-829d-13ac33c86f98/6791';
.wrapper {
  overflow-y: hidden;
}
.container {
  margin: 0 auto;
  width: 980px;
}
@media only screen and (min-width: 1420px) {
  .container {
    margin: 0 auto;
    width: 1260px;
  }
}
