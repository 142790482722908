@import './mixins/index.scss';
html {
  margin: 0 auto;
  font-size: $baseFont + px;
  line-height: 1.75;
  color: $title;
  background-color: $white;
}

html,
body {
  min-height: 100vh;
  height: auto;
  overflow: visible;
  -webkit-overflow-scrolling: touch;
}

body {
  @include open-sans();
  padding: 0;
  margin: 0;
  min-width: $minWidth + px;
  background-color: $white;
  overflow-x: auto;
  position: static;
  word-break: break-word;
  // 适配edge
  overflow-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6,
img,
ul,
li,
a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  color: inherit;
}
