@import './vars.scss';

.apc-modal-wrapper {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
}

.apc-modal-mask {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.apc-modal-header {
  background-color: $white;
  display: flex;
  align-items: center;
  padding: 28px 28px 0;
  position: relative;
  box-sizing: content-box !important;
  height: auto;
  background: none;
}

.apc-modal-title {
  display: inline-block;
  font-style: normal;
  @include shopee-2021-bold();

  font-size: 22px;
  color: $primary;
  margin: 10px auto 0;
  &:nth-child(n + 2) {
    padding-left: 12px;
  }
}

.apc-modal {
  position: absolute;
  width: 680px;
  min-height: 400px;
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.apc-modal-body {
  margin: 24px 28px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.apc-modal-footer {
  // margin-top: 28px;
  padding: 0 28px 28px;
  text-align: right;

  .sg-button + .sg-button {
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.apc-modal-confirm {
  .apc-modal-body {
    color: black;
  }

  .apc-modal-footer {
    // margin-top: 32px;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

.animated {
  animation-duration: 200ms;
  animation-fill-mode: both;
}
