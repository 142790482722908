@import './vars.scss';
@import './mixins/index.scss';
// ui-demo分隔符,默认样式修改
hr {
    margin: 20px 0 10px 0;
}

h1 {
    @include shopee-2021-bold();
    line-height: 48px;
    padding: 4px;
}

h2 {
    font-size: 42px;
    @include shopee-2021-bold();
}

h3 {
    margin: 10px 0 5px 0;
}

// Input组件
.sg-input {
    input {
        height: 100%;
        // border-radius: 20px !important;
        font-size: 16px !important;
        color: rgba(32, 35, 37, 0.6) !important;
        @include open-sans();
        &::placeholder {
            font-size: 16px !important;
            @include open-sans();
        }
    }
    &-prefix {
        display: flex !important;
        align-items: center !important;
        left: 8px !important;
    }
}

// Button组件
.sg-button {
    button {
        height: 36px !important;
        border-radius: 20px !important;
        width: unset !important;
        @include open-sans();
        &.big {
            border-radius: 28px !important;
        }
        &.primary {
            // padding: 6px 20px !important;
            background: $primary !important;
            &:hover {
                box-shadow: 0 2px 8px 0 rgba(238, 77, 43, 0.5);
            }
            &:active {
                background-color: rgba(200, 38, 20, 1) !important;
                opacity: unset;
                box-shadow: 0 2px 8px 0 rgba(238, 77, 43, 0.5);
            }
        }
        &.default {
            color: $primary !important;
            &:hover {
                background: none;
            }
        }
    }
}

.sg-tabs {
    .sg-tabs-tab.sg-tabs-tab-active {
        color: $primary;
    }
    .sg-tabs-arrow {
        border-bottom: 18px solid $primary;
    }
}

.sg-pagination {
    justify-content: flex-end !important;
    border: none !important;
    .sg-tips {
        display: none;
    }
    &-item {
        span {
            height: 24px;
        }
        &-pre {
            padding-right: 0 !important;
            flex: none !important;
            &:not(.sg-pagination-item-disable) {
                svg {
                    color: $primary !important;
                }
            }
        }
        &-next {
            padding-right: 0 !important;
            flex: none !important;
            color: $primary !important;
            &:not(.sg-pagination-item-disable) {
                svg {
                    color: $primary !important;
                }
            }
        }
        &-disable {
            color: rgba(0, 0, 0, 0.09) !important;
        }
        margin-right: 0 !important;
        margin-left: 0 !important;
        height: 64px !important;
        // flex: 1 !important;
        background-color: #f7f8fc;
        border: none !important;
        border-radius: 0 !important;
    }
}

.sg-selection {
    border-radius: 28px !important;
    border: 1px solid $primary;
    @include open-sans();
    height: 56px;
    cursor: pointer;
    &NotExpanded {
        border-color: rgba(0, 0, 0, 0.26) !important;
    }
    &Content {
        margin-left: 16px !important;
        margin-top: 9px !important;
    }
    .sg-selectedValue {
        @include open-sans();
        font-size: 16px;
    }
    .sg-selectedValue:not(.sg-placeholder) {
        color: rgba(32, 35, 37, 0.6) !important;
    }
}

.sg-positionContainer {
    .sg-optionWrapper:hover {
        background-color: $primaryHover;
    }
    .sg-optionWrapperSelected {
        background-color: $primaryHover;
        .sg-selected {
            color: $primary;
        }
    }
}

.sg-checkIcon {
    color: $primary !important;
}

.sg-collapse-item-header {
    @include open-sans();
}

.sg-tabs-tab {
    padding: 0;
    &:hover {
        color: $primary;
    }
}

.sg-optionWrapper {
    line-height: 1;
}

.sg-optionContent {
    @include open-sans();
}

.sg-tabs-header {
    border-bottom: 4px solid $primary;
    box-sizing: border-box;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
}

.sg-tabs-tabOfTopArrow {
    border: none;
}

.sg-pagination-item {
    background-color: unset !important;
}

.sg-pagination-item:hover a,
.sg-pagination-item-active a {
    color: $primary !important;
}

.sg-pagination-item:hover a div,
.sg-pagination-item-active a div {
    background: $primary !important;
}