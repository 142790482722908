$cdnURL: 'https://negosyo.test.shopee.ph/publish_static/c3ec22f6-90d5-4979-829d-13ac33c86f98/6791';
@import 'vars';
@import 'fonts';
@import 'reset';
@import 'mixins';
@import 'editor';
@import 'overwriteSgDesign';
@import 'modal';

// 悬浮滚动条解决闪烁问题
html {
  // Overlay
  overflow-y: scroll;
  overflow-y: overlay;
}
